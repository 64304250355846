import { CommonModule } from '@angular/common'
import { Component, OnInit, WritableSignal, inject, signal } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { Store } from '@ngrx/store'
import { NgxSpinnerModule } from 'ngx-spinner'
import { SplashScreenComponent } from './shared'
import * as fromRoot from './store'
import * as fromMatches from './store/matches'
import * as fromPositionsTable from './store/positions-table'

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    NgxSpinnerModule,
    SplashScreenComponent,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private _store: Store<fromRoot.State>;

  public $showSplash: WritableSignal<boolean>;

  constructor() {
    this._store = inject(Store);

    this.$showSplash = signal(true);
  }

  ngOnInit(): void {
    setTimeout((): void => {
      this.$showSplash.set(false);

      this._store.dispatch(fromMatches.getMatchesAction());
      this._store.dispatch(fromPositionsTable.getPositionsTableAction());
    }, 3300);
  }
}
