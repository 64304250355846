import { ActionReducerMap } from '@ngrx/store'
import * as fromMatches from './matches'
import * as fromPositionsTable from './positions-table'

export interface State {
  matches: fromMatches.MatchesState;
  positionsTable: fromPositionsTable.PositionsTableState;
}

export const reducers: ActionReducerMap<State> = {
  matches: fromMatches.matchesReducers,
  positionsTable: fromPositionsTable.positionsTableReducers
};

export const effects = [
  fromMatches.MatchesEffects,
  fromPositionsTable.PositionsTableEffects
]