import { Routes } from '@angular/router'
import { tokenGuard } from './guards'
import { RoutesUrl } from './routes.enum'

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: RoutesUrl.MAIN,
        pathMatch: 'full'
      },
      {
        path: RoutesUrl.MAIN,
        loadChildren: () => import('./pages/main/main.routes').then(r => r.MainRoutes),
        canActivate: [tokenGuard]
      },
      {
        path: RoutesUrl.POSITIONS_TABLE,
        loadChildren: () => import('./pages/positions-table/positions-table.routes').then(r => r.PositionsTableRoutes)
      }
    ]
  }
];
